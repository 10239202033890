import './App.css';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/system';
import { Tooltip, createTheme, Collapse, Accordion, AccordionDetails, AccordionSummary, Divider } from '@mui/material';
import tmpBG from './assets/temp-bg.png';

const defaultTheme = createTheme();
const theme = createTheme(defaultTheme, {
  palette: {
    background: {
      paper: 'rgba(255,255,255,0.9)',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
    action: {
      active: '#001E3C',
    },
    success: {
      dark: '#009688',
    },
  },
});

const CustomDivider = () => <Divider sx={{margin: '15px 0px'}}/>;

function App() {
  const [tooltipState, setTooltipState] = useState(false);
  const [collapseState, setCollapseState] = useState(false);
  const emailDestination = 'contact@hypertechconsulting.com';
  const publicKey = `
-----BEGIN PGP PUBLIC KEY BLOCK-----\n
Version: OpenPGP.js v4.10.10 \n
Comment: https://openpgpjs.org \n

xjMEYWy8bRYJKwYBBAHaRw8BAQdAfjrdn8u4Bv2a/zLTSaD92E2VxfHUk7KA
DbZu0MmGp4DNQWNvbnRhY3RAaHlwZXJ0ZWNoY29uc3VsdGluZy5jb20gPGNv
bnRhY3RAaHlwZXJ0ZWNoY29uc3VsdGluZy5jb20+wo8EEBYKACAFAmFsvG0G
CwkHCAMCBBUICgIEFgIBAAIZAQIbAwIeAQAhCRD/BPH+BtUZNRYhBIhEaCbz
dgS9qNCAN/8E8f4G1Rk1c3sA/jHY05MdgPDX3lh9Lw+QpGQvVHdbR140a19u
JeBjyU7hAQC9Jb3yZocUa/W7Qqz1HCINOucw7QxcczH7lt63go5dDc44BGFs
vG0SCisGAQQBl1UBBQEBB0C0UeQsfaPl2g+gIk+eAoYBZFx2n2d9zY5oJzyA
V7SRUQMBCAfCeAQYFggACQUCYWy8bQIbDAAhCRD/BPH+BtUZNRYhBIhEaCbz
dgS9qNCAN/8E8f4G1Rk1b+QBAP8OTzhOSEf9GP6e/GgJQByb+l5Irwsve9om
Wv7cbhHXAP9TrrRKypzRTryojaQVh+HgbURUKfT9oNPQZ+aESCf1Aw==
=Bnky\n
-----END PGP PUBLIC KEY BLOCK-----`;

  const saveKeyToClipboard = () => {
    console.log('NAVIGATOR:', navigator);
    navigator.clipboard.writeText(publicKey);
    setTooltipState(true);

    const interval = setInterval(() => {
      setTooltipState(false);
      clearInterval(interval);
    }, 2000)
  };

  return (
    <ThemeProvider theme={theme} >
      <Box
        component='div'
        sx={{
          position: 'fixed',
          width: 1/1,
          minHeight: '100vh',
          background: `url(${tmpBG})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: '-1000'
        }}
      >

      </Box>
      <Box
        component='div'
        sx={{
          display: 'flex',
          width: {
            xs: 1/1
          },
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component='div'
          sx={{
            display: 'block',
            width: {
              xs: 3/4,
              lg: 1/3,
            },
            height: 'fit-content',
            bgcolor: 'background.paper',
            padding: '50px 50px',
            '> p': {
              lineHeight: '1.5em'
            }
          }}
        >
          <Box
            component='h1'
            sx={{
              width: 1/1,
              color: 'text.primary',
              textAlign: 'center',
            }}
          >
            Hypertech Consulting
          </Box>
          <Box
            component='p'
            sx={{
              width: 1/1,
              color: 'text.secondary',
              textAlign: 'center',
              padding: '20px 0px 0px 0px'
            }}
          >
            Centered in the great Pacific Northwest, Hypertech Consulting is a premiere provider of digital consulting and contracting services.
            We specialize in web application architecture and design, cloud infrastructure management and deployment, custom e-commerce platforms,
            database design and development, and many other technical disciplines.
          </Box>
          <Box
            component='p'
            sx={{
              width: 1/1,
              color: 'text.secondary',
              textAlign: 'center',
              // padding: '20px 0px'
            }}
          >
            With nearly 10 years of experience in the industry, our founders have helped some of the Pacific Northwest's most notable companies build
            lightweight, globally accessible platforms that deliver content and data to millions of people around the globe. With this new endeavour
            they're hoping to leverage that expertise to empower the region's small businesses and help them grow their digital footprint.
          </Box>
          <Box
            component='p'
            sx={{
              width: 1/1,
              color: 'text.secondary',
              textAlign: 'center',
              padding: '0px 0px 20px 0px'
            }}
          >
            Our website is still under construction right now, but we're fully open for business and accepting new clients every day.
          </Box>

          <Divider />

          <Box
            component='h3'
            sx={{
              color: 'text.primary'
            }}
          >Frequently Asked Questions</Box>

          <Accordion>
            <AccordionSummary sx={{ fontWeight: 'bold'}}>What You Do?</AccordionSummary>
            <AccordionDetails>
              <CustomDivider />
              We provide all manner of services to assist in bringing your technology stack into the 21st century.
              From simple website design and development, to engineering globally scalable cloud based
              infrastructure services for your custom app, we have the experience all the way up and down the stack
              that you need to bring your application or service to life.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary sx={{ fontWeight: 'bold'}}>Who are your typical customers?</AccordionSummary>
            <AccordionDetails>
              <CustomDivider />
              Right now our typical customers are other businesses who do not have the technical experience or knowledge
              of how to run and manage a software development project. That's where we come in. We work with businesses
              directly to provide them with technical, engineering, and management services based on their specific needs.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary sx={{ fontWeight: 'bold'}}>How do you charge?</AccordionSummary>
            <AccordionDetails>
              <CustomDivider />

              Currently we charge by the hour, or per project based on the contract we establish. Once the rates and pay
              periods are established based on the contract we'll invoice you accordingly through a third party vendor
              we use called stripe.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary sx={{ fontWeight: 'bold'}}>What are your Rates?</AccordionSummary>
            <AccordionDetails>
              <CustomDivider  />

              Our rates vary based on the project scope, duration, and nature of the work. However our general guidelines
              for rates are as follows:

              <Box component='p' sx={{ fontSize: 'caption.fontSize'}}>Web and Graphic Design: $90/hr</Box>
              <Box component='p' sx={{ fontSize: 'caption.fontSize'}}>Web Application, Cloud / Microservice, or DevOps Engineering Work: $120/hr</Box>
{/*              <Box component='p' sx={{ fontSize: 'caption.fontSize'}}>- Frontend Development work using Modern frameworks such as React.js or Vue.js: $70/hr</Box>
              <Box component='p' sx={{ fontSize: 'caption.fontSize'}}>- Backend Development such as building out APIs or custom API integrations: $80 / hr</Box>
              <Box component='p' sx={{ fontSize: 'caption.fontSize'}}>- AWS or other Cloud based Microservice and Infrastructure work we usually charge $90 / hour</Box>
              <Box component='p' sx={{ fontSize: 'caption.fontSize'}}>- Software Architecture and Roadmapping, or any other Technical Management / Lead work we usually charge $100 / hr.</Box>*/}

              For complex projects that involve multiple services, an averaged hourly rate will usually be negotiated and specified in the contract.
            </AccordionDetails>
          </Accordion>

          <Divider sx={{marginTop: '20px'}} />

          <Box
            component='p'
            sx={{
              width: 1/1,
              color: 'text.secondary',
              textAlign: 'center'
            }}
          >
            For more information, or if you're looking for help with building your application please email us at <strong><i>{emailDestination}</i></strong>
          </Box>
          <Tooltip
            placement='top'
            title='Copied!'
            open={window.isSecureContext ? tooltipState : false}
            handleOpen={() => setTooltipState(true)}
            handleClose={() => setTooltipState(false)}
            arrow
          >
            <Box
              component='p'
              sx={{
                width: 1/1,
                color: 'text.secondary',
                textAlign: 'center',
                cursor: 'pointer',
                ':hover': {
                  color: 'text.primary',
                },
                ':active': {
                  color: 'action.active'
                }
              }}
              onClick={() => window.isSecureContext ? saveKeyToClipboard() : setCollapseState(!collapseState)}
            >
              {window.isSecureContext ? 'Copy PGP Key to Clipboard' : (collapseState ? 'Hide PGP Key' : 'Reveal PGP Key')}
            </Box>
          </Tooltip>
          <Collapse in={collapseState}>
            <Box
              component='p'
              sx={{
                width: 1/1,
                color: 'background.paper',
                // textAlign: 'center',
                backgroundColor: 'text.primary',
                fontFamily: 'monospace',
                border: '1px solid black',
                borderRadius: '2px',
                padding: '15px',
              }}
            >
              {publicKey}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
